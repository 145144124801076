import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type StatusType = 'success' | 'failure'

export interface IShowToastPayload {
  zoneId?: number
  message: string
  status?: StatusType
}

interface IToastState {
  isShow: boolean
  message: string
  status: StatusType
}

interface IInitialState {
  [key: number]: IToastState
}

export const name = 'toast'

const initialState: IInitialState = {}

const toastSlice = createSlice({
  name,
  initialState,
  reducers: {
    show: (state, action: PayloadAction<IShowToastPayload>) => {
      const { zoneId = 0, message = '', status = 'success' } = action.payload
      state[zoneId] = {
        isShow: true,
        message,
        status,
      }
    },
    hide: (state, action: PayloadAction<{ zoneId?: number }>) => {
      const { zoneId = 0 } = action.payload
      state[zoneId] = { ...state[zoneId], isShow: false }
    },
  },
})

export const { actions: toastActions } = toastSlice

export default toastSlice.reducer
