import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum DialogType {
  VoteSuccessDialog = 'VOTE_SUCCESS_DIALOG',
  OutOfVoteDialog = 'OUT_OF_VOTE_DIALOG',
  VoucherRewardDialog = 'VOUCHER_REWARD_DIALOG',
  CoinRewardDialog = 'COIN_REWARD_DIALOG',
}

export interface IOpenDialogPayload {
  zoneId?: number
  dialogType: DialogType
  candidateName?: string
  quantity?: string
  candidateID?: number
}

export const name = 'dialogs'

const initialState = {}

const dialogsSlice = createSlice({
  name,
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<IOpenDialogPayload>) => {
      const { zoneId = 0, dialogType, candidateName, quantity, candidateID } = action.payload
      state[zoneId] = { type: dialogType }
      if (candidateName) state[zoneId].candidateName = candidateName
      if (candidateID) state[zoneId].candidateID = candidateID
      if (quantity) state[zoneId].quantity = quantity
    },
    closeDialog: (state, action: PayloadAction<{ zoneId?: number }>) => {
      const { zoneId = 0 } = action.payload
      state[zoneId].type = ''
    },
  },
})

export const { actions: dialogsActions } = dialogsSlice

export default dialogsSlice.reducer
