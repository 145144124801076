import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { getRandomNumber, randomID, requestTimeout } from 'shared/utils'

const appApiAxios = axios.create({
  baseURL: '',
  timeout: 60000,
  withCredentials: true,
})

appApiAxios.interceptors.request.use(
  (request) => {
    request.headers['Trace-Id'] = randomID()
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)

appApiAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

interface IAxiosRetry {
  method?: AxiosRequestConfig['method']
  url: AxiosRequestConfig['url']
  data?: AxiosRequestConfig['data']
  retries?: number
  timeWait?: number
  lastError?: AxiosError
}

const axiosRetry = async ({
  method = 'GET',
  url,
  data,
  retries = 5,
  timeWait = 100,
  lastError,
  ...rest
}: IAxiosRetry) => {
  const options: AxiosRequestConfig = { method, url, data, ...rest }
  if (retries === 0) throw lastError
  try {
    return await appApiAxios(options)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.response?.status === 425) {
      await new Promise((resolve) => requestTimeout(() => resolve(''), timeWait))
      return axiosRetry({
        url,
        data,
        retries: retries - 1,
        timeWait: timeWait * 2 * getRandomNumber(0.4, 1.4),
        lastError: err as AxiosError,
        method,
        ...rest,
      })
    } else throw err
  }
}

export { appApiAxios, axiosRetry }
