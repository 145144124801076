import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { campaignApi } from 'services/campaign'
import { currentSessionSlice } from 'services/currentSession'
import { setupListeners } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'
import toastReducer, { name as toastSliceName } from 'components/Toast/slice'
import homeReducer, { name as homeSliceName } from 'domains/Home/slice'
import dialogsReducer, { name as dialogsSliceName } from 'components/Dialogs/slice'
import { shareSlice } from 'services/share'

const rootReducer = combineReducers({
  [campaignApi.reducerPath]: campaignApi.reducer,
  [currentSessionSlice.name]: currentSessionSlice.reducer,
  [shareSlice.name]: shareSlice.reducer,
  [toastSliceName]: toastReducer,
  [dialogsSliceName]: dialogsReducer,
  [homeSliceName]: homeReducer,
})

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.ENV !== 'live',
  middleware: (getDefaultMiddleware) => {
    return [...getDefaultMiddleware(), campaignApi.middleware]
  },
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
