import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { AxiosRequestConfig, AxiosError } from 'axios'
import { appApiAxios } from 'shared/axios-settings'
import { IAxiosBaseQueryError } from 'types'

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
    },
    unknown,
    IAxiosBaseQueryError
  > =>
  async ({ url, method = 'GET', data }) => {
    try {
      const result = await appApiAxios({ url: url, method, data })
      return { data: result.data.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: { status: err.response?.status as number, data: err.response?.data },
      }
    }
  }

export default axiosBaseQuery
