import React from 'react'
import Script from 'next/script'

const WebBridgeLoader = ({ wbLoadedCallback }: { wbLoadedCallback?: () => void }) => {
  const onLoadHandler = () =>
    window.connectWebViewJavascriptBridge((bridge) => {
      bridge.init(() => {})
      wbLoadedCallback?.()
    })

  return (
    <Script
      src="https://storage.googleapis.com/shopeevn-general/shopeevn-general/scripts/new2/wb.js"
      strategy="beforeInteractive"
      onLoad={onLoadHandler}
    />
  )
}

export default React.memo(WebBridgeLoader)
