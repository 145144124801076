import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { appApiAxios } from 'shared/axios-settings'
import { getCookie, handleRequestError } from 'shared/utils'
import { getAppSignature, getDeviceID, sharingAppHandler, showWebBridgeToast } from 'shared/webBridge'
import { RootState, useAppDispatch } from 'store'
import { IFriendInfo, IGetShareLinkData, ISharingHandler, ObjectType } from 'types'

export const name = 'shareApi'
const GET_FRIEND_LIST_LIMIT = 10

interface IInitialState {
  friends: { data?: IFriendInfo[]; isLoading: boolean; error?; isReachingEnd: boolean }
}

const initialState: IInitialState = {
  friends: {
    data: undefined,
    isLoading: true,
    error: undefined,
    isReachingEnd: false,
  },
}

export const getFriendList = createAsyncThunk(
  `${name}/getFriendList`,
  async ({ campaignCode, offset = 0 }: { campaignCode: string; offset?: number }, { rejectWithValue }) => {
    try {
      const deviceID = await getDeviceID()
      const res = await appApiAxios.get(
        `/share/friends/?campaign_code=${campaignCode}&device_id=${deviceID}&offset=${offset}&limit=${GET_FRIEND_LIST_LIMIT}`,
      )
      const { data, total } = res.data
      return { data, total }
    } catch (err) {
      return rejectWithValue(handleRequestError(err))
    }
  },
)

interface IShareViaChatParams {
  shareID: string
  micrositeID: string
  toUserID: number
  appSignature: string
}

export const shareViaChat = createAsyncThunk(
  `${name}/shareViaChat`,
  async ({ shareID, micrositeID, toUserID, appSignature }: IShareViaChatParams, { dispatch, rejectWithValue }) => {
    try {
      await appApiAxios.post('/share/share-via-chat/', {
        share_id: shareID,
        microsite_id: micrositeID,
        dest_user_id: toUserID,
        app_signature: appSignature,
      })

      //* addShare after shareViaChat success
      dispatch(addShare({ shareID, toUserID }))
      return null
    } catch (err) {
      showWebBridgeToast(
        `${i18next.t('Ui! Có lỗi xảy ra mất rồi.')} ${i18next.t('Bạn vui lòng thử lại nha!')}`,
        'failure',
      )
      return rejectWithValue(handleRequestError(err))
    }
  },
)

export const getShareLink = createAsyncThunk(
  `${name}/getShareLink`,
  async (
    { campaignCode, snsType, objectType, micrositeID, zoneCode, candidateID }: ISharingHandler,
    { rejectWithValue },
  ) => {
    let getShareLinkUrl = `/share/share-link/?campaign_code=${campaignCode}&microsite_id=${micrositeID}&sns_type=${snsType}&object_type=${objectType}`
    if (zoneCode) getShareLinkUrl += `&zone_code=${zoneCode}`
    if (candidateID) getShareLinkUrl += `&candidate_id=${candidateID}`
    try {
      const res = await appApiAxios.get(getShareLinkUrl)
      const {
        share_id: shareID,
        share_link: shareLink,
        share_content: shareContent,
        candidate_share_image_url: shareImage,
      } = res.data.data
      return { shareID, shareLink, shareContent, shareImage } as IGetShareLinkData
    } catch (err) {
      return rejectWithValue(handleRequestError(err))
    }
  },
)

const addShare = createAsyncThunk(
  `${name}/addShare`,
  async ({ shareID, toUserID = 0 }: { shareID: string; toUserID?: number }, { rejectWithValue }) => {
    try {
      const res = await appApiAxios.post('/share/add-share/', {
        share_id: shareID,
        dest_shopee_user_id: toUserID,
      })
      const { share_chance_incr: shareChanceIncr } = res.data.data

      if (shareChanceIncr > 0)
        showWebBridgeToast(
          i18next.t('Bạn vừa nhận thêm {{count}} lượt bình chọn', { count: shareChanceIncr }),
          'success',
        )
      else showWebBridgeToast(i18next.t('Rất tiếc, bạn đã nhận tối đa lượt bình chọn'), 'failure')

      return shareChanceIncr
    } catch (err) {
      return rejectWithValue(handleRequestError(err))
    }
  },
)

export const sharingHandler = (sharingHandlerParams: ISharingHandler) => async (dispatch) => {
  const getShareLinkRes = await dispatch(getShareLink(sharingHandlerParams))

  if (getShareLink.fulfilled.match(getShareLinkRes)) {
    const { shareID, shareLink, shareContent, shareImage } = getShareLinkRes.payload

    sharingAppHandler(
      sharingHandlerParams.snsType,
      { url: shareLink, content: shareContent, image: shareImage },
      () => {
        // onSuccess
        if (sharingHandlerParams.objectType === 'campaign') dispatch(addShare({ shareID }))
      },
      () => {
        //onFail
      },
    )
  } else showWebBridgeToast(i18next.t('Ui! Có lỗi xảy ra mất rồi.'), 'failure')
}

export const shareSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFriendList.pending, (state) => {
      if (state.friends.data === undefined) state.friends.isLoading = true
    })
    builder.addCase(getFriendList.fulfilled, (state, action: PayloadAction<{ data: IFriendInfo[]; total: number }>) => {
      state.friends.isLoading = false

      if (state.friends.data === undefined) state.friends.data = action.payload.data
      else state.friends.data = [...state.friends.data, ...action.payload.data]

      if (state.friends.data?.length >= action.payload.total || action.payload.total === 0)
        state.friends.isReachingEnd = true

      state.friends.error = undefined
    })
    builder.addCase(getFriendList.rejected, (state, action) => {
      state.friends.isLoading = false
      state.friends.error = action.payload
    })
    builder.addCase(shareViaChat.fulfilled, (state, action) => {
      const { toUserID } = action.meta.arg
      const sharedFriendIndex = state.friends.data?.findIndex((friend) => friend['user_id'] === toUserID)
      if (sharedFriendIndex !== undefined && state.friends.data) state.friends.data[sharedFriendIndex].shared = true
    })
  },
})

export const useGetFriendList = () => {
  const data = useSelector((state: RootState) => state[name].friends.data)
  const isLoading = useSelector((state: RootState) => state[name].friends.isLoading)
  const error = useSelector((state: RootState) => state[name].friends.error)
  const isReachingEnd = useSelector((state: RootState) => state[name].friends.isReachingEnd)
  const [chatShareID, setChatShareID] = useState<string>('')

  const { query, isReady } = useRouter()
  const dispatch = useAppDispatch()
  const campaignCode = query.cid as string
  const micrositeID = query.site as string
  const objectType = query['object_type'] as ObjectType
  const candidateID = query.candidate as string

  useEffect(() => {
    if (!isReady) return

    dispatch(getFriendList({ campaignCode }))
  }, [isReady])

  const loadMore = () => {
    dispatch(getFriendList({ campaignCode, offset: data?.length }))
  }

  const handleShareFriend = async (toUserID: number) => {
    let shareID = ''

    if (!chatShareID) {
      const getShareLinkRes = await dispatch(
        getShareLink({ campaignCode, snsType: 'chat', objectType, micrositeID, candidateID }),
      )
      if (getShareLink.fulfilled.match(getShareLinkRes)) {
        setChatShareID(getShareLinkRes.payload.shareID)
        shareID = getShareLinkRes.payload.shareID
      }
    } else {
      shareID = chatShareID
    }
    const signatureData = {
      to_user_id: toUserID,
      from_user_id: parseInt(getCookie('SPC_U') || '0'),
      msg_type: 0, // MSG_TYPE_TEXT
      entrypoint: 1016, // ENTRY_POINT_VOTING_GROUP_BIDDING
    }
    const appSignature = await getAppSignature(signatureData)

    dispatch(shareViaChat({ shareID, micrositeID, toUserID, appSignature }))
  }

  return {
    data,
    isLoading,
    error,
    isReachingEnd,
    handleShareFriend,
    loadMore,
  }
}
