import router from 'next/router'
import { IPersistentReminder } from 'types'

interface IPersistentReminderList {
  [key: string]: IPersistentReminder[]
}

const PERSISTENT_REMINDER_LIST_KEY = 'spvnVotingReminderList'
let persistentReminderList: IPersistentReminderList

export const getPersistentReminderList = (): IPersistentReminder[] => {
  const cid = router.query.cid as string
  if (!persistentReminderList) {
    persistentReminderList = JSON.parse(localStorage.getItem(PERSISTENT_REMINDER_LIST_KEY) || '{}')

    //* Remove outdated reminder Data from local Storage to avoid full of storage
    Object.entries(persistentReminderList).forEach(([cid, reminderList]) => {
      reminderList.forEach((reminder, idx) => {
        if (reminder.startTime <= Date.now()) persistentReminderList[cid].splice(idx, 1)
      })
      if (persistentReminderList[cid].length === 0) delete persistentReminderList[cid]
    })
    //* Write the filtered data to local storage
    localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
  }

  return persistentReminderList[cid] || []
}

export const updatePersistentReminderList = (reminderList: IPersistentReminder[]) => {
  const cid = router.query.cid as string
  persistentReminderList[cid] = reminderList
  localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
}
