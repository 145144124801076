import { dialogsActions, IOpenDialogPayload } from 'components/Dialogs/slice'
import { IShowToastPayload, toastActions } from 'components/Toast/slice'
import router from 'next/router'
import store from 'store'
import { IRegion, ITimer, IVars, ObjectType, TopLevelDomainType, ZoneStateType } from 'types'

export const getImage = (image: string) => {
  const { baseURL } = getVariables()
  return `${baseURL}/images/${image}`
}

export const getShopeeCDNImage = (image: string, compressed = false) => {
  if (!image) return null
  return `https://cf.shopee.vn/file/${image}${compressed ? '_tn' : ''}`
}

export const getUnifiedLink = (url: string) => {
  const { shopeeUrl } = getVariables()
  return `${shopeeUrl}/universal-link?redir=${encodeURIComponent(url)}&deep_and_deferred=1`
}

export const getSharePageLink = (objectType: ObjectType, zoneCode?: string, candidateID?: number) => {
  const { cid, site } = router.query

  //* use document.referrer to check that we are in web or app
  const from = document.referrer ? 'web' : 'app'
  const { baseURL } = getVariables()
  let sharePageLink = `${baseURL}/share?cid=${cid}&site=${site}&object_type=${objectType}&from=${from}`
  if (zoneCode) sharePageLink += `&zone_code=${zoneCode}`
  if (candidateID) sharePageLink += `&candidate=${candidateID}`
  return sharePageLink
}

export const convertAppPath = (url: string): string => {
  const base64HomeUrl = window.btoa(
    JSON.stringify({
      paths: [{ webNav: { url } }],
    }),
  )
  return `home?navRoute=${window.encodeURIComponent(base64HomeUrl)}`
}

export const convertAppRL = (url: string) => `home?apprl=${encodeURIComponent(url)}`

export const getCookie = (name: string): string | null => {
  if (typeof document === 'undefined') return null
  return (
    document?.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1] || null
  )
}

export const requestTimeout = (fn: () => void, delay: number) => {
  const start = new Date().getTime()
  let rafID: number
  let isLoop = true
  const loop = () => {
    if (isLoop) {
      const current = new Date().getTime()
      const delta = current - start
      if (delta >= delay) fn()
      else rafID = requestAnimationFrame(loop)
    }
  }
  rafID = requestAnimationFrame(loop)
  return {
    id: rafID,
    clear: () => {
      cancelAnimationFrame(rafID)
      isLoop = false
    },
  }
}

export const requestInterval = (fn: () => void, delay: number) => {
  let start = new Date().getTime()
  let rafID: number
  let isLoop = true
  const loop = () => {
    if (isLoop) {
      const current = new Date().getTime()
      const delta = current - start
      if (delta >= delay) {
        fn()
        start = new Date().getTime()
      }
      rafID = requestAnimationFrame(loop)
    }
  }
  rafID = requestAnimationFrame(loop)
  return {
    id: rafID,
    clear: () => {
      cancelAnimationFrame(rafID)
      isLoop = false
    },
  }
}

export const handleRequestError = (err) => {
  // The error is already handled
  if (err?.code) return { status: err.code, data: err }

  // The request don't have the response
  if (!err?.response && err.request) {
    return { status: 403, data: { code: 403, msg: err?.message } }
  }

  // The error don't have the response, mean it not Request error
  if (!err?.response) {
    return { status: 500, data: { code: 500, msg: err?.message || 'Unknown error' } }
  }

  // The formatted error return by Backend
  if (err.response.data?.code) return { status: err.response.status, data: err.response.data }

  // The response error that not handled by Backend
  return {
    status: err.response.status,
    data: { code: err.response.status, msg: err.response.statusText, data: err.response.data },
  }
}

export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    currency: 'VND',
    style: 'currency',
  })
  return formatter.format(+value)
}

export const numberFormatter = (value: number) => {
  return new Intl.NumberFormat('vi-VN', {}).format(value)
}

// This function to check data integrity does not care about security
export const simpleHash = (message: string) => {
  if (message.length === 0) return 0
  return message.split('').reduce((acc, c) => +((acc << 5) - acc + c.charCodeAt(0)), 0)
}

export const sha1Hash = async (message: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  return await window.crypto.subtle.digest('SHA-1', data)
}

export const getMicrositeUrl = (site: string, tag?: string) => {
  const { micrositePrefix } = getVariables()
  return `${micrositePrefix}/${site}${tag ? `#${tag}` : ''}`
}

export const getMicrositeUniversalUrl = (site: string) => {
  const { universalMicrositePrefix } = getVariables()
  return `${universalMicrositePrefix}/${site}?deep_and_deffered=1`
}

const showToastTimers: ITimer[] = []
export const showToast = (payload: IShowToastPayload, timeout = 3000) => {
  const { zoneId = 0 } = payload
  const { toast } = store.getState()

  if (!toast[zoneId]?.isShow) {
    showToastTimers[zoneId]?.clear()
    store.dispatch(toastActions.show(payload))
    showToastTimers[zoneId] = requestTimeout(
      () => store.dispatch(toastActions.hide({ zoneId: payload.zoneId })),
      timeout,
    )
  }
}

export const openDialog = (payload: IOpenDialogPayload) => {
  store.dispatch(dialogsActions.openDialog(payload))
}

export const closeDialog = ({ zoneId }: { zoneId: number }) => {
  store.dispatch(dialogsActions.closeDialog({ zoneId }))
}

export const randomID = (): string => `_${Math.random().toString(36).substr(2, 9)}`

export const getCurrentZoneState = ({ start, end }: { start: number; end: number }): ZoneStateType => {
  const now = Date.now()
  if (now < start) return 'UPCOMING'
  if (start <= now && now <= end) return 'ONGOING'
  return 'ENDED'
}

export const checkIsLogin = (): boolean => {
  const userId = getCookie('SPC_U')
  return userId !== null && userId !== '-'
}

export const transformVoteNumber = (votes: number): string => {
  let votesText = votes.toString()
  const numberLen = votesText.length
  // if (numberLen > 9) {
  //   votesText = `${~~(votes / 1e9)}tỷ+`
  // } else if (numberLen > 6) {
  //   votesText = `${~~(votes / 1e6)}tr+`
  // } else
  if (numberLen > 3) {
    votesText = `${~~(votes / 1e3)}k+`
  }
  return votesText
}

export const removeItemByValue = (arr: Array<string | number>, value: string | number) => {
  arr.forEach((e: string | number, i: number) => {
    if (e === value) arr.splice(i, 1)
  })

  return arr
}

export const getRandomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

export const isLargeScreen = () => (typeof window === undefined ? false : window.innerWidth >= 960)

export const getTopLevelDomain = (hostname: Location['hostname']): TopLevelDomainType => {
  if (!hostname) return 'vn'
  const parts: string[] = hostname.split('.')
  const shopeePartIndex = parts.findIndex((e) => e === 'shopee')

  if (shopeePartIndex < 0) return 'vn'
  return parts.slice(shopeePartIndex + 1).join('.') as TopLevelDomainType
}

export const getLanguage = () =>
  ({
    vn: 'vi',
    sg: 'en',
    'com.my': 'en',
    'co.th': 'th',
    tw: 'zh',
    'co.id': 'id',
    ph: 'en',
    pl: 'pl',
    fr: 'fr',
    es: 'es',
    'com.mx': 'es',
  }[getTopLevelDomain(global?.location?.hostname)])

export const getVariables = () => {
  if (typeof window === 'undefined') return {} as IVars

  const topLevelDomain = getTopLevelDomain(global?.location?.hostname)

  const envVariables = {
    dev: {
      baseURL: `https://local-voting.giaitri.uat.shopee.${topLevelDomain}`,
      apiURL: `https://test-api.giaitri.uat.shopee.${topLevelDomain}/voting`,
    },
    test: {
      baseURL: `https://test-voting.giaitri.uat.shopee.${topLevelDomain}`,
      apiURL: `https://test-api.giaitri.uat.shopee.${topLevelDomain}/voting`,
    },
    uat: {
      baseURL: `https://voting.giaitri.uat.shopee.${topLevelDomain}`,
      apiURL: `https://api.giaitri.uat.shopee.${topLevelDomain}/voting`,
    },
    live: {
      baseURL: `https://voting.giaitri.shopee.${topLevelDomain}`,
      apiURL: `https://api.giaitri.shopee.${topLevelDomain}/voting`,
    },
  }[process.env.ENV_ARG || 'dev']

  const additionalVariables = {
    nonProduction: {
      micrositePrefix: `https://uat.shopee.${topLevelDomain}/m`,
      universalMicrositePrefix: `https://uat.shopee.${topLevelDomain}/universal-link/m`,
      shopeeLoginURL: `https://uat.shopee.${topLevelDomain}/buyer/login`,
      shopeeUrl: `https://uat.shopee.${topLevelDomain}`,
    },
    production: {
      micrositePrefix: `https://shopee.${topLevelDomain}/m`,
      universalMicrositePrefix: `https://shopee.${topLevelDomain}/universal-link/m`,
      shopeeLoginURL: `https://shopee.${topLevelDomain}/buyer/login`,
      shopeeUrl: `https://shopee.${topLevelDomain}`,
    },
  }[process.env.ENV_ARG === 'live' ? 'production' : 'nonProduction']

  return { ...envVariables, ...additionalVariables } as IVars
}

export const getRegion = () =>
  getTopLevelDomain(global?.location?.hostname ?? '')
    .split('.')
    .slice(-1)
    .toString() as IRegion
