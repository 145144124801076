import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { appApiAxios, axiosRetry } from 'shared/axios-settings'
import { handleRequestError, requestInterval } from 'shared/utils'
import { RootState, useAppDispatch } from 'store'
import { ICurrentSession, IPlaceVoteData, ITimer } from 'types'

export const name = 'currentSessionApi'

interface IInitialState {
  data?: {
    zones: ICurrentSession[]
  }
  isLoading: boolean
  error?
}

const initialState: IInitialState = {
  data: undefined,
  isLoading: true,
  error: undefined,
}

export const getCurrentSessionByZoneCodes = createAsyncThunk(
  'currentSessionApi/getCurrentSessionByZoneCodes',
  async (zones: string[], { rejectWithValue }) => {
    try {
      let zonesQuerystring = ''
      zones.forEach((zone, index) => {
        zonesQuerystring += `${index === 0 ? '?' : '&'}zone_codes=${zone}`
      })

      const res = await axiosRetry({ url: `/current-session/${zonesQuerystring}` })
      return res.data.data
    } catch (err) {
      return rejectWithValue(handleRequestError(err))
    }
  },
)

export const getInitialCurrentSession = createAsyncThunk(
  'currentSessionApi/getInitialCurrentSession',
  async (campaginCode: string, { rejectWithValue }) => {
    try {
      const res = await axiosRetry({ url: `/current-session/?campaign_code=${campaginCode}` })
      return res.data.data
    } catch (err) {
      return rejectWithValue(handleRequestError(err))
    }
  },
)

interface IPlaceVoteArgs {
  campaignCode: string
  zoneCode: string
  candidateID: number
}

export const placeVote = createAsyncThunk(
  'currentSessionApi/placeVote',
  async ({ campaignCode, zoneCode, candidateID }: IPlaceVoteArgs, { rejectWithValue }) => {
    try {
      const res = await appApiAxios.post(`/vote/`, {
        campaign_code: campaignCode,
        zone_code: zoneCode,
        candidate_id: candidateID,
      })

      const { ld_result: ldResult } = res.data.data as IPlaceVoteData
      return { ldResult }
    } catch (err) {
      return rejectWithValue(handleRequestError(err))
    }
  },
)

export const currentSessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInitialCurrentSession.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getInitialCurrentSession.fulfilled,
      (state, action: PayloadAction<{ zones: ICurrentSession[] }>) => {
        state.data = action.payload
        state.isLoading = false
      },
    )
    builder.addCase(getInitialCurrentSession.rejected, (state, action) => {
      state.error = action.payload
      state.isLoading = false
    })
    builder.addCase(
      getCurrentSessionByZoneCodes.fulfilled,
      (state, action: PayloadAction<{ zones: ICurrentSession[] }>) => {
        action.payload.zones.forEach((currentSession) => {
          const indexInArray = state.data?.zones.findIndex((zone) => zone.code === currentSession.code)
          if (state.data && indexInArray !== undefined) state.data.zones[indexInArray] = currentSession
        })
      },
    )
  },
})

const pollingZones: string[] = []
const POLLING_INTERVAL = 3000

export const useGetCurrentSession = (campaignCode: string, options?: { skip: boolean }) => {
  const dispatch = useAppDispatch()
  const { data, isLoading, error } = useSelector((state: RootState) => state.currentSessionApi)
  const { skip = false } = options || {}
  const requestData = () => {
    if (pollingZones.length > 0)
      dispatch(getCurrentSessionByZoneCodes(pollingZones)).then((action) => {
        if (getCurrentSessionByZoneCodes.fulfilled.match(action)) {
          action.payload.zones.forEach((zone: ICurrentSession) => {
            if (zone.state === 'ENDED') pollingZones.removeItemByValue(zone.code)
          })
        }
      })
  }

  useEffect(() => {
    if (skip) return

    let timer: ITimer
    dispatch(getInitialCurrentSession(campaignCode)).then((action) => {
      if (getInitialCurrentSession.fulfilled.match(action)) {
        action.payload.zones.forEach((zone: ICurrentSession) => {
          if (zone.state === 'ONGOING') pollingZones.push(zone.code)
        })

        timer = requestInterval(requestData, POLLING_INTERVAL)
      }
    })

    return () => timer?.clear()
  }, [skip])

  return { data, isLoading, error, refetch: requestData }
}

export const addToPollingZones = (zoneCode: string) => {
  pollingZones.push(zoneCode)
}
