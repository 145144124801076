interface Array {
  removeItemByValue(value: string | number): void
}

Array.prototype.removeItemByValue = function (value: number | string) {
  this.forEach((e: string | number, i: number) => {
    if (e === value) this.splice(i, 1)
  })
  return this
}
