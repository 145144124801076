import { setIsLogin } from 'domains/Home/slice'
import i18next from 'i18next'
import store from 'store'
import {
  IAppInfoResult,
  IConfigOptions,
  INavBarOptions,
  IReminderData,
  IShareResult,
  ISharingData,
  ISignatureData,
} from 'types/webBridge'
import { ShareSNSType } from './constants'
import {
  checkIsLogin,
  convertAppRL,
  getImage,
  getLanguage,
  getMicrositeUrl,
  getVariables,
  requestTimeout,
} from './utils'

export const isShopeeApp = () =>
  typeof window !== 'undefined' && window?.navigator?.userAgent?.toLowerCase().indexOf('shopee') !== -1

export const isIOS = (): boolean => {
  if (typeof navigator === 'undefined') return false
  const userAgent = navigator?.userAgent || navigator?.vendor
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
}

export const isAndroid = (): boolean => {
  if (typeof navigator === 'undefined') return false
  const userAgent = navigator?.userAgent || navigator?.vendor
  return /android/i.test(userAgent)
}

export const isPC = () => !isIOS() && !isAndroid()

export const getAppSignature = async (signatureData: ISignatureData) =>
  new Promise<string>((resolve) => {
    const dataValue = {
      to_user_id: signatureData.to_user_id,
      from_user_id: signatureData.from_user_id,
      msg_type: signatureData.msg_type,
      entrypoint: signatureData.entrypoint,
    }
    const param = isIOS() ? { data: dataValue } : dataValue
    window.WebViewJavascriptBridge?.callHandler('getLunaManagerValue', param, (result: { data: { value: string } }) => {
      resolve(result.data.value) // The signature
    })
    setTimeout(() => {
      resolve('-')
    }, 2000)
  })

export const configurePage = (title: string, usingClose: boolean, disableReload: boolean, disableSwipeBack = true) => {
  const navbar: INavBarOptions = {}
  const config: IConfigOptions = {}

  if (title) {
    navbar.title = title
  }

  if (usingClose) {
    navbar.showCloseIcon = 1
    navbar.hideBackButton = 1
  }

  if (disableReload) {
    config.disableReload = 1
    config.disableBounce = 1
  }

  config.disableSwipeBack = disableSwipeBack ? 1 : 0

  window.WebViewJavascriptBridge?.callHandler('configurePage', { config, navbar })
}

export const getDeviceID = async () => {
  const deviceID: string = await new Promise((resolve) => {
    if (isShopeeApp())
      window.WebViewJavascriptBridge?.callHandler('getAppInfo', {}, (res: IAppInfoResult) => {
        resolve(res.deviceID || res.appDeviceID || 'DEVICEIDNOTFOUND')
      })
    else resolve('DEVICEIDNOTFOUND')
  })
  return deviceID
}

export const navigateToUrl = (url: string, navbar?: INavBarOptions) => {
  window.WebViewJavascriptBridge?.callHandler(
    'navigate',
    {
      url,
      popSelf: 1,
      navbar,
    },
    // ({ status }) => {
    //   console.log(status)
    // },
  )
}

const checkUseRnwv = () => {
  let useRnwv = false

  try {
    useRnwv = !!window.top?.ReactNativeWebView
  } catch {
    //
  }

  return useRnwv
}

export const micrositeNavigate = (url?: string) => {
  if (!url) return

  if (checkUseRnwv()) window.top?.ReactNativeWebView.postMessage(JSON.stringify({ cmd: 'navigate', data: url }))
  else window.top?.postMessage(JSON.stringify({ cmd: 'navigate', data: url }), '*')
}

export const micrositeReminder = (reminderData: IReminderData) =>
  new Promise((resolve) => {
    if (checkUseRnwv())
      window.top?.ReactNativeWebView.postMessage(JSON.stringify({ cmd: 'reminder', data: reminderData }))
    else window.top?.postMessage(JSON.stringify({ cmd: 'reminder', data: reminderData }), '*')

    // Must wait for the 500ms the reminder feature to fully triggered before do anything else
    requestTimeout(() => {
      resolve('')
    }, 500)
  })

export const showWebBridgeToast = (message: string, iconType: 'success' | 'failure' = 'success') => {
  window.WebViewJavascriptBridge?.callHandler('showToast', {
    toast: {
      iconType, // Image on toast message. Ignored by Android. Only used by iOS. Available types: success & failure
      message,
    },
  })
}

export const sharingAppHandler = (
  sharingAppID: ShareSNSType,
  sharingData: ISharingData,
  onSuccess: () => void = () => {},
  onFail?: (shared: boolean, appType: ShareSNSType) => void,
) => {
  const callback = ({ errorCode }: IShareResult) => {
    //* ref: https://confluence.shopee.io/pages/viewpage.action?pageId=454442403#AppSharing-ShareResult
    if (errorCode === 0) {
      if (sharingAppID === 'copyLink') showWebBridgeToast(i18next.t('Thông tin đã được sao chép'), 'success')

      if (isIOS() && sharingAppID !== 'zaloTimeline') {
        onSuccess()
      } else {
        const handleOnSucessWithVisibilityChange = () => {
          if (document.visibilityState === 'visible') {
            onSuccess()
            document.removeEventListener('visibilitychange', handleOnSucessWithVisibilityChange, false)
          }
        }
        document.addEventListener('visibilitychange', handleOnSucessWithVisibilityChange, false)
      }
    } else {
      if (errorCode === 2) showWebBridgeToast(i18next.t('Chưa cài app'), 'failure')
      else showWebBridgeToast(i18next.t('Ui! Có lỗi xảy ra mất rồi.'), 'failure')

      onFail?.(false, sharingAppID)
    }
  }
  const { url, content, image } = sharingData
  const BRIDGE_SHARING_DATA = {
    facebookLink: {
      contentUrl: url,
      quote: content,
    },
    facebookMessenger: {
      text: url,
    },
    zaloMessage: {
      url,
    },
    zaloTimeline: {
      url,
    },
    twitter: {
      text: url,
    },
    viber: {
      text: url,
    },
    instagram: {
      image: {
        imageUrl: image || getImage(`share-instagram/insta-${getLanguage()}.png`),
      },
    },
    whatsapp: {
      text: url,
    },
    lineChat: {
      text: url,
    },
    telegram: {
      text: url,
    },
    copyLink: {
      link: url,
    },
  }

  window.WebViewJavascriptBridge?.callHandler(
    'shareData',
    {
      sharingAppID,
      sharingData: BRIDGE_SHARING_DATA[sharingAppID],
    },
    callback,
  )
}

export const didTapBack = (callback: () => void) => {
  window.WebViewJavascriptBridge?.registerHandler('didTapBack', callback)
}

export const micrositeLogin = (site: string, callback?: () => void) => {
  const { shopeeLoginURL } = getVariables()
  const shopeeLoginPage = `${shopeeLoginURL}?next=${getMicrositeUrl(site, 'voting')}`
  const { baseURL } = getVariables()
  if (isAndroid() && !document.referrer) {
    //* Since Android Shopee App have a weird implementation on login, we must have a different flow for it
    micrositeNavigate(`${baseURL}/login?site=${site}`)
  } else {
    micrositeNavigate(shopeeLoginPage)

    //* This handle is use for iOS Shopee App, since it will only close LoginPanel
    const handleReturnFromLogin = () => {
      if (document.visibilityState === 'visible') {
        requestTimeout(() => {
          //* Should delay for sometime to get new cookies
          store.dispatch(setIsLogin(checkIsLogin()))
          callback?.()
        }, 100)
        document.removeEventListener('visibilitychange', handleReturnFromLogin, false)
      }
    }
    document.addEventListener('visibilitychange', handleReturnFromLogin, false)
  }
}

export const webBridgeLogin = (site: string) =>
  new Promise((resolve, reject) => {
    window.WebViewJavascriptBridge?.callHandler(
      'login',
      {
        hidePopup: 1,
        redirectTab: 1,
        redirectPath: convertAppRL(getMicrositeUrl(site, 'voting')),
      },
      (res: { status: 0 | 1 | 2 }) => {
        if (res.status === 1) {
          resolve(res)
        } else {
          reject(res)
        }
      },
    )
  })

export const popWebView = () => {
  window.WebViewJavascriptBridge?.callHandler('deviceScreenAutoLock', { isEnabled: true })
  window.WebViewJavascriptBridge?.callHandler('popWebView', {})
}
