import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en.json'
import vi from './vi.json'
import id from './id.json'
import th from './th.json'
import zh from './zh.json'
import es from './es.json'

const translationsJson = {
  en: { translation: en },
  vi: { translation: vi },
  id: { translation: id },
  th: { translation: th },
  zh: { translation: zh },
  es: { translation: es },
}

const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // compatibilityJSON: 'v3',
    resources: translationsJson,
    // lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
