import 'styles/globals.css'
import 'shared/prototype.d.ts'
import 'locales/i18n'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { Provider } from 'react-redux'
import store from 'store'
import Head from 'next/head'
import { useEffect } from 'react'
import { checkIsLogin, getLanguage, getVariables } from 'shared/utils'
import { setIsLogin } from 'domains/Home/slice'
import WebBridgeLoader from 'shared/WebBridgeLoader'
import GALoader from 'shared/GALoader'
import { ErrorBoundary } from '@sentry/react'
import { useRouter } from 'next/router'
import { pageview } from 'shared/gtag'
import { appApiAxios } from 'shared/axios-settings'
import i18next from 'i18next'

if (typeof window !== 'undefined') i18next.changeLanguage(getLanguage())

const MyApp = ({ Component, pageProps }: AppProps): React.ReactNode => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => pageview(url)

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    appApiAxios.defaults.baseURL = getVariables().apiURL
    // i18next.changeLanguage(getLanguage())
    store.dispatch(setIsLogin(checkIsLogin()))
  }, [])

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        <meta name="description" content="Voting Tool" />
        <meta name="keywords" content="Voting Tool" />
      </Head>
      <Provider store={store}>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </Provider>
      <WebBridgeLoader />
      <GALoader />
    </>
  )
}

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  const { id, name, label, value } = metric
  switch (metric.name) {
    case 'FCP':
      // handle FCP results
      break
    case 'LCP':
      // handle LCP results
      break
    case 'CLS':
      // handle CLS results
      break
    case 'FID':
      // handle FID results
      break
    case 'TTFB':
      // handle TTFB results
      break
    case 'Next.js-hydration':
      // handle hydration results
      break
    case 'Next.js-route-change-to-render':
      // handle route-change to render results
      break
    case 'Next.js-render':
      // handle render results
      break
    default:
      break
  }

  window.gtag?.('event', name, {
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  })
}

export default MyApp
