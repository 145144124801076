import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { updatePersistentReminderList } from 'shared/persistent'
import { IPersistentReminder } from 'types'

export const name = 'home'

interface intitialStateProps {
  reminderList: IPersistentReminder[]
  isLogin: boolean
  error?: { status: number; data: { code: number } }
}

const initialState: intitialStateProps = {
  reminderList: [],
  isLogin: true,
  error: undefined,
}

const homeSlice = createSlice({
  name,
  initialState,
  reducers: {
    setReminderList: (state, action: PayloadAction<IPersistentReminder[]>) => {
      state.reminderList = action.payload
    },
    addReminder: (state, action: PayloadAction<IPersistentReminder>) => {
      state.reminderList = [...state.reminderList, action.payload]

      updatePersistentReminderList(state.reminderList)
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const {
  actions: { setReminderList, addReminder, setIsLogin, setError },
} = homeSlice

export default homeSlice.reducer
